import {
  Box,
  Container,
  SimpleGrid,
  Card,
  Heading,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
} from "@chakra-ui/react";
import CamperTable from "../components/tables/CamperTable";
import CountUp from "react-countup";
import axios from "axios";
import { useState, useEffect } from "react";
import AdminNavigation from "../components/AdminNavigation";
import ShirtSizeTable from "../components/tables/ShirtSizeTable";
import { toast } from "react-hot-toast";
import GroupsTable from "../components/tables/GroupsTable";
import ConfettiExplosion from "react-confetti-explosion";
export default function () {
  const [campers, setCampers] = useState(null);
  const [total, setTotal] = useState(0);
  const [sizes, setSizes] = useState(null);
  const [isExploding, setIsExploding] = useState(false);

  const showSuccess = () => toast.success("Updated!");
  const showLoading = () => toast.loading("Updating Payment Information!");

  const grabCampers = (updating) => {
    var s = "";
    if (updating) {
      s = showLoading();
    }

    axios
      .get("https://api.houstonsikhyouthcamp.org/campers", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        // setCampers(res.data.data);
        var campersFormed = [];
        var total = 0;
        res.data.data.map((datapoint) => {
          var tempCamperObject = {};
          datapoint.campersInformation.map((camperInfo) => {
            tempCamperObject = Object.assign(tempCamperObject, camperInfo);
            tempCamperObject = Object.assign(
              tempCamperObject,
              datapoint.guardianInformation
            );
            campersFormed.push(tempCamperObject);
            total = total + 1;
            tempCamperObject = {};
          });
        });
        setCampers(campersFormed);
        setTotal(total);
        // setTotal(res.data.total);
        if (updating) {
          toast.dismiss(s);
          showSuccess();
        }
      });

    // axios
    //   .get("https://api.houstonsikhyouthcamp.org/campers", {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     setTotal(res.data.total);
    //   });
  };
  // useEffect(() => {
  //   setIsExploding(true);
  // }, [total]);

  useEffect(() => {
    grabCampers();
  }, []);

  // const updateCampers = () => {
  //   axios.get("https://api.houstonsikhyouthcamp.org/camper", {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   });
  // };
  const create_shirts_distribution = () => {
    var created_distribution = {};
    // campers?.map((camper_obj) => {
    //   camper_obj.campersInformation.map((camper) => {
    //     if (!(camper.shirtSize in created_distribution)) {
    //       var keyName = camper.shirtSize;
    //       created_distribution[keyName] = 1;
    //     } else {
    //       var keyName = camper.shirtSize;
    //       created_distribution[keyName] += 1;
    //     }
    //   });
    // });
    campers?.map((camper) => {
      if (!(camper.shirtSize in created_distribution)) {
        var keyName = camper.shirtSize;
        created_distribution[keyName] = 1;
      } else {
        var keyName = camper.shirtSize;
        created_distribution[keyName] += 1;
      }
    });
    setSizes(created_distribution);
  };

  useEffect(() => {
    create_shirts_distribution();
  }, [campers]);
  return (
    <>
      <AdminNavigation />
      <Box as="section" py={{ base: "4", md: "8" }}>
        <Heading
          as={"h3"}
          size={"2xl"}
          color="black"
          mb={"1rem"}
          textAlign={"center"}
        >
          Admin Panel
        </Heading>
        <Box maxW="75%" style={{ marginLeft: "auto", marginRight: "auto" }}>
          {true && <ConfettiExplosion />}
          <SimpleGrid spacing={3} columns={[1, null, 3]}>
            <Card bg="#dee2e6">
              <CardBody>
                {/* <Text as={"b"} fontSize={"6xl"}>
                  <CountUp end={total} />
                </Text> */}
                {/* <ShirtSizeTable camper_shirt_sizes={sizes ? sizes : {}} /> */}
                <GroupsTable campers={campers} />
              </CardBody>
            </Card>
            <Card maxH={"10em"} bg="#dee2e6">
              <CardBody>
                <Container textAlign={"center"}>
                  <Text as={"b"} fontSize={"6xl"}>
                    <CountUp end={total} />
                  </Text>
                  <Heading size="sm">Registered Campers</Heading>
                </Container>
              </CardBody>
            </Card>
            <Card bg="#dee2e6">
              <CardBody>
                {/* <Text as={"b"} fontSize={"6xl"}>
                  <CountUp end={total} />
                </Text> */}
                <ShirtSizeTable camper_shirt_sizes={sizes ? sizes : {}} />
              </CardBody>
            </Card>
          </SimpleGrid>
        </Box>
      </Box>

      <CamperTable campers={campers} callback_request={grabCampers} />
      <Container h={"5em"}></Container>
    </>
  );
}
