import {
  Container,
  Heading,
  Divider,
  Text,
  Link,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import colors from "../utils/color_codes";
export default function ContactSection() {
  const contact_info = [
    { name: "HARDEEP SINGH", field: "713.459.4812", type: "tel" },
    { name: "SUKHPREET KAUR", field: "713.291.5749", type: "tel" },
    { name: "PARMINDER SINGH", field: "713.385.3914", type: "tel" },
    // { name: "SARGUN KAUR", field: "832.764.6552", type: "tel" },
    {
      name: "OFFICIAL HSYC EMAIL",
      field: "houstonsikhyouthcamp@gmail.com",
      type: "mailto",
    },
  ];
  return (
    <>
      <Container
        centerContent
        bg={colors.textColor}
        maxW="100%"
        p={"2em"}
        color="black"
        id="contact-section"
      >
        <Heading as={"h3"} size={"xl"} mb={"0rem"}>
          CONTACT
        </Heading>
        <Divider mb={"1rem"} maxW="50%" height="0em" color={"black"} />
        <Grid templateColumns="repeat(4, 1fr)" gap={2} width={"100%"}>
          {contact_info.map((c) => {
            return (
              <>
                <GridItem
                  maxW="100%"
                  margin="auto"
                  colSpan={{ base: 6, md: 1, lg: 1, xl: 1 }}
                  textAlign={"center"}
                >
                  <Text as="b" mb="0.15rem" mt="0.5rem">
                    {c.name}
                  </Text>
                  <Text>
                    <Link href={`${c.type + ":" + c.field}`} isExternal>
                      {c.field}
                    </Link>
                  </Text>
                </GridItem>
              </>
            );
          })}
        </Grid>
      </Container>
    </>
  );
}
